import { Helmet } from 'react-helmet-async';
import { injectComponent } from '@mediashop/app/component-injector';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Corporation, WithContext } from 'schema-dts';
import { useProject } from '@mediashop/app/hooks/useProject';

function StructuredData() {
    const { shippingCountries, store } = useProject();

    const activiteSchemaCh: WithContext<Corporation> = {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'Mediashop Schweiz AG',
        alternateName: 'Activité',
        url: 'https://www.activite-online.ch/ch/de',
        logo: 'https://www.activite-online.ch/_img/100_100/site/binaries/content/gallery/images/activite/logos/logo_activite_varianten-05.svg',
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: '0554500152',
            contactType: 'customer service',
            areaServed: shippingCountries,
            availableLanguage: ['German', 'fr'],
        },
    };

    const activiteSchemaDeAt: WithContext<Corporation> = {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'Mediashop Schweiz AG',
        alternateName: 'Activité',
        url: 'https://www.activite-online.com',
        logo: 'https://www.activite-online.com/_img/100_100/site/binaries/content/gallery/images/activite/logos/logo_activite_varianten-05.svg',
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: '08004009920',
            contactType: 'customer service',
            areaServed: shippingCountries,
            availableLanguage: 'German',
        },
    };

    return (
        <Helmet
            script={[
                store === 'catalog_Activite_ch' ? helmetJsonLdProp(activiteSchemaCh) : '',
                store === 'catalog_Activite_de-at' ? helmetJsonLdProp(activiteSchemaDeAt) : '',
            ]}
        ></Helmet>
    );
}

export default injectComponent('pattern.template.Meta.StructuredData', StructuredData, 'activite');
